import { EligiblityCampaignConstant as CampaignConstant } from '../constant';
const initialState = {
  payload: null,
  statusUpdateCampaign: null,
  type: '',
  message: '',
  loading: false,
};

export default function eligibilityCampaignReducers(
  state = initialState,
  action
) {
  switch (action.type) {
    case CampaignConstant.UPDATECAMPAIGNSUCCESS:
      return {
        ...state,
        updatedDefaultEntries: action.payload,
      };
    case CampaignConstant.GETCAMPAIGNLISTSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case CampaignConstant.GETCAMPAIGNLISTFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case CampaignConstant.SAVECAMPAIGNSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case CampaignConstant.SAVECAMPAIGNFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case CampaignConstant.DELETECAMPAIGNSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case CampaignConstant.DELETECAMPAIGNFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };

    case CampaignConstant.UPDATE_CONTENT_STATUS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case CampaignConstant.UPDATE_CONTENT_STATUS_FAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };

    case CampaignConstant.CLEAR_DATA:
      return {
        ...state,
        type: action.type,
      };

    case CampaignConstant.SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case CampaignConstant.SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case CampaignConstant.CLEAR_TYPE:
      return {
        ...state,
        type: '',
      };
    case CampaignConstant.UPDATE_CAMPAIGN_STATUS_SUCCESS:
      return {
        ...state,
        type: action.type,
        message: action.message,
        statusUpdateCampaign: action.payload,
      };
    case CampaignConstant.UPDATE_CAMPAIGN_STATUS_FAIL:
      return {
        ...state,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case CampaignConstant.CLEAR_ALL_DATA:
      return initialState;

    // eslint-disable-next-line no-duplicate-case
    case CampaignConstant.CLEAR_TYPE:
      return {
        ...state,
        type: '',
      };
    default:
      return state;
  }
}
