export const CreateEligibilityCampaignConstant = {
  DEFAULTERR: 'sorry unable to process! please try again later',

  CLEARALL: 'CLEAR_ALL',

  SETOPENEDCAMPAIGN: 'SET_OPENED_CAMPAIGN',
  SETOPENEDCAMPAIGNSTARTDATE: 'SET_OPENED_CAMPAIGN_STARTDATE',
  SETOPENEDCAMPAIGNENDDATE: 'SET_OPENED_CAMPAIGN_ENDDATE',

  SETSELECTEDCHANNELS: 'SET_SELECTED_CHANNELS',
  SETAUDIENCES: 'SET_AUDIENCES',

  ADDAUDIENCE: 'ADD_AUDIENCE',
  UPDATEAUDIENCE: 'UPDATE_AUDIENCE',
  REMOVEAUDIENCE: 'REMOVE_AUDIENCE',

  SETOPENEDCHANNEL: 'SET_OPENED_CHANNEL',
  SETOPENEDAUDIENCE: 'SET_OPENED_AUDIENCE',
};
