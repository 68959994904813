export const CreateRedirectionCampaignConstant = {
  DEFAULTERR: 'sorry unable to process! please try again later',

  CLEARALL: 'CLEAR_ALL',

  SETOPENEDCAMPAIGN: 'SET_OPENED_CAMPAIGN',
  SETOPENEDCAMPAIGNSTARTDATE: 'SET_OPENED_CAMPAIGN_STARTDATE',
  SETOPENEDCAMPAIGNENDDATE: 'SET_OPENED_CAMPAIGN_ENDDATE',

  SETSELECTEDCHANNELS: 'SET_SELECTED_CHANNELS',
  SETAUDIENCES: 'SET_AUDIENCES',
  SETSEGMENTS: 'SET_SEGMENTS',

  ADDAUDIENCE: 'ADD_AUDIENCE',
  UPDATEAUDIENCE: 'UPDATE_AUDIENCE',
  REMOVEAUDIENCE: 'REMOVE_AUDIENCE',

  ADDSEGMENT: 'ADD_SEGMENT',
  UPDATESEGMENT: 'UPDATE_SEGMENT',
  REMOVESEGMENT: 'REMOVE_SEGMENT',

  SAVECONTENTMAPPING: 'SAVE_CONTENT_MAPPING',

  SETOPENEDCHANNEL: 'SET_OPENED_CHANNEL',
  SETOPENEDAUDIENCE: 'SET_OPENED_AUDIENCE',
  SETOPENEDSEGMENT: 'SET_OPENED_SEGMENT',

  ADD_EXPERIENCE_KEYS: 'ADD_EXPERIENCE_KEYS',
};
