export const SearchCampaignConstants = {
  FETCH_SEARCH_CAMPAIGN_DATA_REQUEST: 'FETCH_SEARCH_CAMPAIGN_DATA_REQUEST',
  FETCH_SEARCH_CAMPAIGN_DATA_SUCCESS: 'FETCH_SEARCH_CAMPAIGN_DATA_SUCCESS',
  FETCH_SEARCH_CAMPAIGN_DATA_FAILURE: 'FETCH_SEARCH_CAMPAIGN_DATA_FAILURE',
  SET_CAMPAIGN_TYPES: 'SET_CAMPAIGN_TYPES',
  SET_SELECTED_TYPES: 'SET_SELECTED_TYPES',
  SET_FILTER_PARAMS: 'SET_FILTER_PARAMS',
  SET_SELECTED_CHANNELS: 'SET_SELECTED_CHANNELS',
  DEFAULT_ERROR: 'An error occurred while fetching search campaign data.',
};
