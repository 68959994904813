import { SearchCampaignConstants } from 'redux/constant/searchCampaignConstant';

const initialState = {
  data: null,
  loading: false,
  error: null,
  selectedTypes: '',
  campaignTypes: '',
  filterParams: {},
  selectedChannels: [],
};

const searchCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case SearchCampaignConstants.FETCH_SEARCH_CAMPAIGN_DATA_REQUEST:
      return { ...state, loading: true, error: null };

    case SearchCampaignConstants.FETCH_SEARCH_CAMPAIGN_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case SearchCampaignConstants.FETCH_SEARCH_CAMPAIGN_DATA_FAILURE:
      return { ...state, loading: false, error: action.message };

    case SearchCampaignConstants.SET_SELECTED_TYPES:
      return { ...state, selectedTypes: action.payload };

    case SearchCampaignConstants.SET_CAMPAIGN_TYPES:
      return { ...state, campaignTypes: action.payload };

    case SearchCampaignConstants.SET_FILTER_PARAMS:
      return { ...state, filterParams: action.payload };

    case SearchCampaignConstants.SET_SELECTED_CHANNELS:
      return { ...state, selectedChannels: action.payload };

    default:
      return state;
  }
};

export default searchCampaignReducer;
