import { RedirectionCampaignConstant } from '../constant';
const initialState = {
  payload: null,
  statusUpdateCampaign: null,
  type: '',
  message: '',
  contentExperience: {},
  isGeneral: false,
  abTestingDetailCopy: {},
  updatedDefaultEntries: [],
  personalizationDetailCopy: {},
  loading: false,
};

export default function redirectionCampaignReducers(
  state = initialState,
  action
) {
  switch (action.type) {
    case RedirectionCampaignConstant.UPDATECAMPAIGNSUCCESS:
      return {
        ...state,
        updatedDefaultEntries: action.payload,
      };
    case RedirectionCampaignConstant.GETCAMPAIGNLISTSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.GETCAMPAIGNLISTFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.SAVECAMPAIGNSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.SAVECAMPAIGNFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.DELETECAMPAIGNSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.DELETECAMPAIGNFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.CHANGECAMPAIGNPRIORITYSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.CHANGECAMPAIGNPRIORITYFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.SAVECONTENTABTESTCAMPAIGNSUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case RedirectionCampaignConstant.SAVECONTENTABTESTCAMPAIGNFAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.SAVE_CONTENT_PERSONALIZATION_CAMPAIGN_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case RedirectionCampaignConstant.SAVE_CONTENT_PERSONALIZATION_CAMPAIGN_FAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
      };
    case RedirectionCampaignConstant.GET_CONTENT_EXPERINCE_SUCCESS:
      return {
        ...state,
        abTestingDetail: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case RedirectionCampaignConstant.GET_CONTENT_EXPERINCE_COPY_SUCCESS:
      return {
        ...state,
        abTestingDetail: action.payload,
        type: RedirectionCampaignConstant.GET_CONTENT_EXPERINCE_SUCCESS,
        message: action.message,
        description: action.description,
        abTestingDetailCopy: action.payload,
      };

    case RedirectionCampaignConstant.GET_CONTENT_EXPERINCE_FAIL:
      return {
        ...state,
        abTestingDetail: null,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case RedirectionCampaignConstant.GET_CONTENT_PERSONALIZATION_SUCCESS:
      return {
        ...state,
        personalizationDetail: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case RedirectionCampaignConstant.UPDATE_CONTENT_STATUS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case RedirectionCampaignConstant.UPDATE_CONTENT_STATUS_FAIL:
      return {
        ...state,
        payload: action.payload,
        type: action.type,
        message: action.message,
        description: action.description,
      };

    case RedirectionCampaignConstant.GET_CONTENT_PERSONALIZATION_COPY_SUCCESS:
      return {
        ...state,
        personalizationDetailCopy: action.payload,
        personalizationDetail: action.payload,
        type: RedirectionCampaignConstant.GET_CONTENT_PERSONALIZATION_SUCCESS,
        message: action.message,
        description: action.description,
      };
    case RedirectionCampaignConstant.GET_CONTENT_PERSONALIZATION_FAIL:
      return {
        ...state,
        personalizationDetail: null,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case RedirectionCampaignConstant.CLEAR_DATA:
      return {
        ...state,
        type: action.type,
      };
    case RedirectionCampaignConstant.DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };
    case RedirectionCampaignConstant.DELETE_CONTENT_FAIL:
      return {
        ...state,
        type: action.type,
      };
    case RedirectionCampaignConstant.SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case RedirectionCampaignConstant.SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case RedirectionCampaignConstant.CLEAR_TYPE:
      return {
        ...state,
        type: '',
      };
    case RedirectionCampaignConstant.UPDATE_CAMPAIGN_STATUS_SUCCESS:
      return {
        ...state,
        type: action.type,
        message: action.message,
        statusUpdateCampaign: action.payload,
      };
    case RedirectionCampaignConstant.UPDATE_CAMPAIGN_STATUS_FAIL:
      return {
        ...state,
        type: action.type,
        message: action.message,
        description: action.description,
      };
    case RedirectionCampaignConstant.CLEAR_ALL_DATA:
      return initialState;

    // eslint-disable-next-line no-duplicate-case
    case RedirectionCampaignConstant.CLEAR_TYPE:
      return {
        ...state,
        type: '',
      };
    default:
      return state;
  }
}
